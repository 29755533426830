import { forwardRef, useEffect, useId, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRemixFormContext } from "remix-hook-form";

import { ICheckboxGroupProps } from "./type";

import { Checkbox } from "~/components/atoms/Checkbox";
import { Icon } from "~/components/atoms/Icons";
import Typo from "~/components/atoms/Typo";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { EIcon } from "~/utilities/enums/Icons";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export type Ref = HTMLInputElement;

const CheckboxGroup = forwardRef<Ref, ICheckboxGroupProps>(
  (
    {
      options = [],
      label,
      register,
      variant,
      showSecondIcon = false,
      classListCheckbox = "",
      wrapperClass = "",
      itemClass = "",
      labelClass = "",
      checkboxSize = "sm",
    },
    ref
  ) => {
    const { t } = useTranslation();
    const {
      getValues,
      formState: { isDirty, defaultValues },
    } = useRemixFormContext();

    const [localValue, setLocalValue] = useState<string[]>([]);
    const [collapse, setCollapse] = useState<boolean>(true);
    const [isBrowser, setIsBrowser] = useState<boolean>(false);

    useEffect(() => {
      setIsBrowser(true);
      if (isBrowser) {
        const initialValues = getValues(register?.name || "");
        setLocalValue(initialValues);
      }
    }, [getValues, register?.name, isBrowser]);

    useEffect(() => {
      if (isBrowser && !isDirty && defaultValues) {
        setLocalValue(defaultValues[register?.name || ""]);
      }
    }, [isDirty, defaultValues, register?.name, isBrowser]);

    const handleChange = (id: string, checked: boolean) => {
      const updatedValue = checked
        ? [...localValue, id]
        : localValue.filter((val) => val !== id);
      setLocalValue(updatedValue);
      if (register && register.onChange) {
        register.onChange({
          target: {
            name: register.name,
            value: updatedValue,
          },
        });
      }
    };

    const getColorClass = (variant: string) => {
      switch (variant) {
        case "contactSales":
          return "text-contactSalesText";
        case "enquiry":
          return "text-enquiryText";
        case "contactUs":
          return "text-formContactUsText";
        default:
          return "text-color";
      }
    };

    const getTypoColor = (variant: string) => {
      switch (variant) {
        case "contactSales":
          return ETypoColor.CONTACT_SALES;
        case "enquiry":
          return ETypoColor.ENQUIRY;
        case "contactUs":
          return ETypoColor.FORM_CONTACT_US;
        default:
          return ETypoColor.TEXT;
      }
    };

    const getCheckboxClass = (variant: string) => {
      switch (variant) {
        case "contactSales":
          return "!border-contactSalesCheckboxBorder";
        case "enquiry":
          return "!border-enquiryCheckboxBorder";
        case "contactUs":
          return "!border-formContactUsCheckboxBorder";
        case "ldp":
          return "!border-trnansparent";
        default:
          return "!border-enquiryCheckboxBorder";
      }
    };

    const componentId = useId();

    return (
      <div
        className={cn("flex flex-col gap-3", wrapperClass)}
        key={`${label}-${register?.name}`}
      >
        {label && (
          <Typo
            color={getTypoColor(variant ?? "")}
            tag={ETypoTag.P}
            variant={ETypoVariant.HEADER_16}
            className={cn(
              `font-bold ${getColorClass(variant ?? "")}`,
              labelClass
            )}
          >
            {label}
          </Typo>
        )}

        {options.length > 0 && (
          <div
            className={cn(
              "grid grid-cols-1 gap-3",
              classListCheckbox,
              variant === "contactUs" && "grid-cols-1 lg:grid-cols-1"
            )}
          >
            {options
              .slice(0, collapse ? 12 : options.length)
              .map(
                ({
                  id,
                  name,
                  checkboxContainerClass = "",
                  icon = undefined,
                  secondIcon = undefined,
                }) => (
                  <div
                    key={`${register?.name}-${id}`}
                    className={cn("col-span-1 flex items-center", itemClass)}
                  >
                    <Checkbox
                      color="success"
                      checked={localValue?.includes(id)}
                      onChange={(e) => handleChange(id, e.target.checked)}
                      id={`${register?.name}_${id}_${componentId}`}
                      value={id}
                      size={checkboxSize}
                      label={name}
                      icon={showSecondIcon ? secondIcon : icon}
                      labelClass={cn(
                        getColorClass(variant ?? ""),
                        "break-words whitespace-pre-wrap"
                      )}
                      checkboxClass={cn(getCheckboxClass(variant ?? ""))}
                      checkboxContainerClass={checkboxContainerClass}
                    />
                  </div>
                )
              )}
          </div>
        )}

        {options.length > 12 && (
          <div className="flex w-full items-center justify-center">
            <button
              type="button"
              onClick={() => setCollapse(!collapse)}
              className="flex cursor-pointer items-center justify-center gap-1 hover:underline"
            >
              <Typo
                tag={ETypoTag.SPAN}
                variant={ETypoVariant.BODY_TITLE_14}
                className={getColorClass(variant ?? "")}
              >
                {collapse ? t("view_more") : t("view_less")}
              </Typo>
              <Icon
                TypeIcon={EIcon.ChevronDownIcon}
                height={20}
                width={20}
                className={cn(
                  "fill-white60 transition-all duration-300",
                  !collapse && "rotate-180"
                )}
              />
            </button>
          </div>
        )}
      </div>
    );
  }
);

CheckboxGroup.displayName = "CheckboxGroup";

export default CheckboxGroup;
